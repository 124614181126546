import React, { useState, useEffect } from 'react'
import { Breadcrumbs as MUIBreadcrumbs, Link, Box } from '@mui/material'
import { GridFilterModel } from '@mui/x-data-grid-premium'
import { filterStore } from '../stores/FilterStore'

import OverflowPopup from './OverflowPopup/OverflowPopup'

import useResponsive from '../hooks/useResponsive'

interface Crumb {
  title: string
  path: string
  isDocument: boolean
}
interface FiltercrumbsFieldAndHeaderProps {
  // eslint-disable-next-line react/require-default-props
  field: string
  headerName: string
}

interface FiltercrumbsProps {
  dataGridId: string // Unique identifier for the DataGrid
  // eslint-disable-next-line react/require-default-props
  originalRowCount?: number // Optional, as it may not always be provided
  // eslint-disable-next-line react/require-default-props
  fieldsAndHeaders?: FiltercrumbsFieldAndHeaderProps[]
  // eslint-disable-next-line react/require-default-props
  initialFilterModel?: GridFilterModel[]
}

const Filtercrumbs: React.FC<FiltercrumbsProps> = ({
  dataGridId = 'data-grid',
  originalRowCount = 0,
  fieldsAndHeaders = [],
  initialFilterModel = [],
}) => {
  const isDesktop = useResponsive('up', 'md')
  const [crumbs, setCrumbs] = useState([])
  const filterModel = filterStore.getFilterModel(dataGridId)
  const items = filterModel?.items || initialFilterModel?.items || []

  const getCrumbs = (): Crumb[] => {
    const crumby: Crumb[] = []

    if (items.length > 0) {
      crumby.push({
        title: `${originalRowCount} Rows Filtered By:`,
        path: '/filters',
        isDocument: false,
      })

      items.forEach((item: any) => {
        const fieldAndHeader = fieldsAndHeaders.find(
          row => row.field === item.field
        )
        const line = `${fieldAndHeader.headerName} ${item.operator} ${item.value}`
        crumby.push({
          title: line.replace(/:slashPlaceHolder:/g, '/'),
          path: '/filters',
          isDocument: false,
        })
      })
    }

    return crumby.filter(crumb => crumb.title && crumb.path) as Crumb[]
  }

  useEffect(() => {
    const newCrumbs = getCrumbs
    setCrumbs(newCrumbs)
  }, [originalRowCount, filterModel]) // Watch changes for both pathname and row count

  const lastCrumbIdx = crumbs.length - 1

  if (crumbs.length === 0) {
    return null
  }

  return (
    <MUIBreadcrumbs
      maxItems={4 + +isDesktop}
      itemsBeforeCollapse={0}
      itemsAfterCollapse={4 + +isDesktop}
      sx={{
        display: 'inline-flex',
        justifyContent: 'flex-start',
        lineHeight: 1,
        marginLeft: '0.5em',
        width: '100%',
        '& .MuiBreadcrumbs-ol': {
          flexWrap: 'nowrap',
          maxWidth: crumbs.length > 2 ? { xs: '50%', md: '85%' } : undefined,
          '& .MuiBreadcrumbs-li': {
            maxWidth: { xs: '15rem', md: '25rem' },
          },
        },
      }}
    >
      {crumbs.map((crumb, idx) => (
        <Box key={crumb.path}>
          {idx === lastCrumbIdx || crumb.disabled ? (
            <OverflowPopup
              content={crumb.title}
              fullWidth
              variant='caption'
              sx={{
                color:
                  idx === 0 && !crumb.disabled ? 'primary.main' : undefined,
                cursor: 'default',
                fontWeight: idx === 0 && !crumb.disabled ? 600 : undefined,
              }}
            />
          ) : (
            <Link key={crumb.path} underline='hover'>
              <OverflowPopup
                content={crumb.title}
                fullWidth
                variant='caption'
                sx={{
                  cursor: 'pointer',
                  fontWeight: idx === 0 ? 600 : undefined,
                }}
              />
            </Link>
          )}
        </Box>
      ))}
    </MUIBreadcrumbs>
  )
}

export default Filtercrumbs
