/* eslint no-shadow: 0 */
import {
  action,
  computed,
  IObservableArray,
  makeObservable,
  observable,
  override,
  toJS,
} from 'mobx'
import moment from 'moment'

import ContactManagement from './ContactManagement'
import ModelBase from './ModelBase'
import MemberRecords from './MemberRecords'
import MemberRecordsRecordProperty from './MemberRecordsRecordProperty'
import OrgAddress from './OrgAddress'
import OrgGroup from './OrgGroup'
import OrgMember from './OrgMember'
import RelationshipManagement from './RelationshipManagement'

import RootStore from '../stores/RootStore'

import { useLog } from '../lib/log'
import {
  ExternalCRMEvent,
  ExternalCRMTask,
  ExternalCRMOptions,
  FirmHoldingOption,
  FirmInvestmentSummary,
  HostGlobalGroup,
  InvestmentHousehold,
  InvestmentSummary,
  InvestmentFirmRevenue,
  InvestmentHouseholdsPerformance,
  InvestmentReportsMetaData,
  InvestmentProjectedIncome,
  PerformanceProviderOptions,
  OrgConfig,
  OrgMemberProps,
  OrgProps,
  UserType,
  InvestmentDonatedSecurity,
} from '../types'
import { generateID } from '../lib/utils'

const log = useLog()

const organizationProfileProps = [
  '_id',
  'isHost',
  'isDisabled',
  'showAnalytics',
  'showOutreach',
  'hideMembersByHost',
  'hideMembersByOrg',
  'hideEvents',
  'hideForms',
  'hideInvestments',
  'hideMessages',
  'hidePolls',
  'hidePosts',
  'hideTodos',
  'legal_business_name',
  'phone',
  'source_of_funds',
  'tax_id',
  'logo',
  'accounts',
  'investments_dashboard',
  'address',
  'host',
  'asset_allocations',
  'stripe_customer_id',
  'external_contact_id',
  'config',
  'quarterlyInvestmentReportsMetaData',
  'business_description',
  'users',
  'external_contact_id',
  'isEmailDigestEnabled',
]

const emptyAllocation = {
  class: '',
  subclass: '',
  minimum: '0',
  maximum: '0',
  target: '0',
}

const defaultConfig: OrgConfig = {
  allowEmbedMeetings: false,
  allowHostDiscussions: false,
  disableMemberDocumentUploads: false,
  branding: {
    urlBase: '',
  },
  globalGroups: [],
  globalFolders: [],
  inviteTemplate: '',
  useHostInviteTemplate: false,
  performanceReporting: {
    allowDateRange: false,
    provider: '',
    reports: [],
  },
  externalCRM: { provider: '' },
  showInvestmentInvoicing: false,
  themeProperties: {
    body_font: false,
    heading_font: false,
    theme: false,
  },
}

export default class Organization extends ModelBase {
  constructor(
    rootStore: RootStore,
    org: OrgProps = {
      address: undefined,
      config: defaultConfig,
      groups: undefined,
      external_contact_id: '',
      hideEvents: false,
      hideForms: false,
      hideInvestmentBilling: true,
      hideInvestments: true,
      hideMembersByHost: false,
      hideMessages: false,
      hidePolls: false,
      hidePosts: false,
      hideTodos: false,
      host: undefined,
      host_admins: [],
      hostGlobalGroups: [],
      hostGroups: undefined,
      host_name: undefined,
      showAnalytics: false,
      showNewReports: true,
      showOutreach: false,
      isEmailDigestEnabled: false,
      defaultPostId: '',
    }
  ) {
    super(rootStore)

    makeObservable(this, {
      _id: observable,
      host: observable,
      hostName: observable,
      isHost: observable,
      isDisabled: observable,
      showAnalytics: observable,
      showOutreach: observable,
      showNewReports: observable,
      hideEvents: observable,
      hideForms: observable,
      hideInvestments: observable,
      hideMembers: computed,
      hideMembersByHost: observable,
      hideMembersByOrg: observable,
      hideMessages: observable,
      hidePolls: observable,
      hidePosts: observable,
      hideTodos: observable,
      legal_business_name: observable,
      address: observable,
      phone: observable,
      source_of_funds: observable,
      tax_id: observable,
      logo: observable,
      accounts: observable,
      quarterlyInvestmentReportsMetaData: observable,
      hostQuarterlyInvestmentReportsMetaData: observable,
      stripe_customer_id: observable,
      investments_dashboard: observable,
      members: observable,
      users: observable,
      hostUsers: observable,
      hostGlobalGroups: observable,
      groups: observable,
      hostGroups: observable,
      memberRecords: observable,
      createdAt: observable,
      inviteLog: observable,
      memberRoleChanged: observable,
      loadingMembers: observable,
      profileError: observable,
      profileSuccess: observable,
      profileMsg: observable,
      validationErrors: observable,
      newGroup: observable,
      asset_allocations: observable,
      config: observable,
      addAssetAllocation: action,
      removeAssetAllocation: action,
      data: override,
      shouldShowMessages: computed,
      getMembers: action,
      memberCount: computed,
      getMemberRecords: action,
      getMembersMemberRecord: action,
      registeringMember: observable,
      registeringMemberSuccess: observable,
      registeringMemberError: observable,
      registerMember: action,
      updateAllPendingMemberDetails: action,
      sendInviteLink: action,
      updateInvitedOrPendingMember: action,
      getInviteLog: action,
      groupSaving: observable,
      groupSuccess: observable,
      saveGroup: action,
      reloadGroups: action,
      removeGroup: action,
      restoreGroup: action,
      removeMember: action,
      changeMemberExpiration: action,
      changeMemberRole: action,
      loadingInvestmentsEmbeds: observable,
      loadingInvestmentsLooks: observable,
      investmentsEmbeds: observable,
      getInvestmentsEmbeds: action,
      orgSaving: observable,
      orgSaveSuccess: observable,
      orgSaveError: observable,
      orgValidationErrors: observable,
      impersonatingId: observable,
      save: action,
      saveOrgProfile: action,
      inviteTemplate: computed,
      useHostInviteTemplate: computed,
      toggleUseHostInviteTemplate: action,
      themeProperties: computed,
      latestSignups: computed,
      saveInviteTemplate: action,
      investmentAssetAdjustments: observable,
      getInvestmentAssetAdjustments: action,
      getInvestmenBondHoldings: action,
      getHistoricInvestmentSummary: action,
      investmentAccounts: observable,
      getInvestmentAccounts: action,
      investmentBuySellByAccount: observable,
      getInvestmentBuySellByAccount: action,
      investmentHouseholds: observable,
      getInvestmentHouseholds: action,
      getInvestmentLastPositionDate: action,
      getInvestmentHouseholdPerformance: action,
      getInvestmentHistoricPerformance: action,
      getInvestmentSummaryByRange: action,
      getInvestmentTransactions: action,
      getInvestmentExpenses: action,
      getInvestmentIncomes: action,
      getInvestmentBenchmarks: action,
      investmentBalances: observable,
      getInvestmentBalances: action,
      investmentBalancesByAccount: observable,
      annualInvestmentSummary: observable,
      historicInvestmentSummary: observable,
      inceptionInvestmentSummary: observable,
      investmentBenchmarks: observable,
      inventmentBondHoldings: observable,
      investmentExpenses: observable,
      investmentHistoricBalances: observable,
      investmentHistoricPerformance: observable,
      investmentHouseholdHoldings: observable,
      investmentHouseholdPerformance: observable,
      investmentIncomes: observable,
      investmentPositions: observable,
      investmentProjectedIncome: observable,
      investmentSPYBreakdown: observable,
      investmentSummaries: observable,
      investmentTransactions: observable,
      canShowFirmInvestments: computed,
      getAnnualInvestmentSummary: action,
      getEvestechInvestmentInvoices: action,
      getInvestmentBalancesByAccount: action,
      getInvestmentHistoricBalances: action,
      getInvestmentHouseholdHoldings: action,
      getInvestmentProjectedIncome: action,
      getInceptionInvestmentSummary: action,
      getInvestmentSPYBreakdown: action,
      investmentTargetAllocations: observable,
      getInvestmentTargetAllocations: action,
      getInvestmentInvoices: action,
      getInvestmentPositions: action,
      getInvestmentAUM: action,
      getFirmDonatedSecurities: action,
      getInvestmentFirmHoldings: action,
      getInvestmentFirmRevenues: action,
      getInvestmentFirmSummary: action,
      firmSummary: observable,
      fetchSmartTagData: action,
      getSavedQuarterlyInvestmentReports: action,
      saveQuarterlyInvestmentReports: action,
      investmentFirmRevenues: observable,
      resetInvestments: action,
      showInvestmentBilling: computed,
      showInvestmentInvoicing: computed,
      showInvestmentsBondHoldings: computed,
      showInvestmentReports: computed,
      showInvestmentsSummary: computed,
      showInvestmentsAllocationByClass: computed,
      showInvestmentsAllocationBySector: computed,
      showInvestmentsAllocationDetail: computed,
      showInvestmentsAllocationTargets: computed,
      showInvestmentsBenchmarks: computed,
      showInvestmentsHistoricalSummary: computed,
      showInvestmentsProjectedIncome: computed,
      showInvestmentsPerformanceTable: computed,
      showInvestmentsAnnualPerformanceChart: computed,
      showInvestmentsInceptionPerformanceChart: computed,
      showInvestmentsStrategyPerformance: computed,
      hasGlobalGroups: computed,
      isHostWithGlobalGroups: computed,
      resources: observable,
      getResources: action,
      convertHtmlToPdf: action,
      performanceReportingProvider: computed,
      performanceReportingProviderDisplayName: computed,
      crmEvents: observable,
      crmTasks: observable,
      externalCRMProvider: computed,
      externalCRMProviderDisplayName: computed,
      getCRMEvents: action,
      getCRMTasks: action,
      external_contact_id: observable,
      isHostUser: observable,
      contactManagement: observable,
      getContactManagement: action,
      relationshipManagement: observable,
      getRelationshipManagement: action,
      showAssistance: computed,
      getMemberDetailsById: action,
      getMember: action,
      isEmailDigestEnabled: observable,
      defaultPostId: observable,
      memberSelectOptions: computed,
      getMembersMemberRecordAndProp: action,
      inactiveMembers: observable,
    })

    this.loadData(org)
  }

  loadData = (org: OrgProps) => {
    Object.assign(this, org)
    this.newGroup = new OrgGroup(this.rootStore, {})
    this.address = new OrgAddress(org.address)
    this.showNewReports = true
    this.hostGlobalGroups = org.hostGlobalGroups || []
    this.groups = org.groups
      ? org.groups.map((g: { id: string }) => {
          const hostGlobalGroup = this.hostGlobalGroups.find(
            (hgg: { id: string }) => hgg.id === g.id
          )
          let orgGroupProps = { ...g }
          if (hostGlobalGroup && typeof hostGlobalGroup === 'object') {
            orgGroupProps = {
              ...g,
              ...hostGlobalGroup,
            }
          }
          return new OrgGroup(this.rootStore, orgGroupProps)
        })
      : []
    this.hostGroups =
      !this.isHost && org.hostGroups && org.hostGroups.length > 0
        ? org.hostGroups.map(
            g => new OrgGroup(this.rootStore, { ...g, isHostGroup: true })
          )
        : []
    this.impersonatingId = localStorage.getItem('impersonatingId') || undefined
    this.config = {
      ...defaultConfig,
      ...org.config,
    }
    this.hostUsers = org.host_admins || []
    this.hostName = org.host_name || undefined
    this.parseMembersResponse(org.members || [])
    this.handleGlobalGroups()
    this.handleAdminsGroup()

    if (this.rootStore?.orgStore?.currentOrg) {
      this.relationshipManagement = new RelationshipManagement(
        this.rootStore,
        org.relationshipManagement || {}
      )
    }
  }

  reloadData = async () => {
    if (this._id) {
      const { data } = await this.rootStore.client.org.getOrgById(this._id)
      this.loadData(data)
    }
  }

  modelCollection = 'organizations'

  _id: string | undefined = undefined

  host: string | undefined = undefined

  hostName: string | undefined = undefined

  isHost = false

  isHostUser = false

  isDisabled = false

  showAnalytics = false

  showOutreach = false

  showNewReports = true

  hideEvents = false

  hideForms = false

  hideInvestmentBilling = true

  hideInvestments = true

  hideMembersByHost = false

  hideMembersByOrg = false

  hideMessages = false

  hidePolls = false

  hidePosts = false

  hideTodos = false

  legal_business_name = ''

  address: OrgAddress | undefined = undefined

  phone = ''

  source_of_funds = ''

  tax_id = ''

  logo = ''

  accounts: unknown[] = []

  quarterlyInvestmentReportsMetaData: IObservableArray<InvestmentReportsMetaData> =
    observable.array([])

  hostQuarterlyInvestmentReportsMetaData: IObservableArray<InvestmentReportsMetaData> =
    observable.array([])

  stripe_customer_id = ''

  external_contact_id: string | undefined = ''

  investments_dashboard = [
    { dashboard: 9 },
    { dashboard: 10 },
    { dashboard: 16 },
  ]

  members: IObservableArray<OrgMember> = observable.array([])

  inactiveMembers: IObservableArray<OrgMember> = observable.array([])

  users: IObservableArray<UserType> = observable.array([])

  hostUsers: string[] = []

  hostGlobalGroups: HostGlobalGroup[] = []

  groups: OrgGroup[] = []

  hostGroups: OrgGroup[] = []

  memberRecords = new MemberRecords(this.rootStore, {
    org_id: undefined,
    properties: undefined,
  })

  createdAt = Date.now()

  inviteLog: unknown[] = []

  memberRoleChanged = false

  loadingMembers = false

  profileError = false

  profileSuccess = false

  profileMsg: string | undefined = undefined

  validationErrors = {}

  newGroup: OrgGroup = undefined

  asset_allocations: unknown[] = []

  config = defaultConfig

  crmEvents: ExternalCRMEvent[] = []

  crmTasks: ExternalCRMTask[] = []

  addAssetAllocation() {
    this.asset_allocations.push(emptyAllocation)
  }

  removeAssetAllocation(index: number) {
    this.asset_allocations = this.asset_allocations.filter(
      (aa, i) => i !== index
    )
  }

  firmHoldingTypes: FirmHoldingOption[] = []

  firmHoldingSubTypes: FirmHoldingOption[] = []

  isEmailDigestEnabled = false

  defaultPostId = ''

  fetchSmartTagData = async () => {
    localStorage.removeItem('firmHoldingTypes')
    localStorage.removeItem('firmHoldingSubTypes')

    if (this.isHost) {
      if (!this.firmHoldingTypes || this.firmHoldingTypes.length === 0) {
        try {
          const { data: byType } =
            await this.client.org.fetchHoldingTypeOptions(this._id)

          if (byType) {
            this.firmHoldingTypes = byType
          }

          const { data: bySubTypeType } =
            await this.client.org.fetchHoldingSubTypeOptions(this._id)

          if (bySubTypeType) {
            this.firmHoldingSubTypes = bySubTypeType
          }
        } catch (e) {
          //
        }
      }

      const currentOrgId = sessionStorage.getItem('currentOrgId')

      if (this._id === currentOrgId) {
        localStorage.setItem(
          'firmHoldingTypes',
          JSON.stringify(toJS(this.firmHoldingTypes))
        )
        localStorage.setItem(
          'firmHoldingSubTypes',
          JSON.stringify(toJS(this.firmHoldingSubTypes))
        )
      }
    }
  }

  get data() {
    const data = organizationProfileProps.reduce(
      (
        acc,
        key:
          | '_id'
          | 'isHost'
          | 'isDisabled'
          | 'showAnalytics'
          | 'showOutreach'
          | 'hideEvents'
          | 'hideForms'
          | 'hideInvestments'
          | 'hideMembersByHost'
          | 'hideMembersByOrg'
          | 'hideMessages'
          | 'hidePolls'
          | 'hidePosts'
          | 'hideTodos'
          | 'legal_business_name'
          | 'phone'
          | 'source_of_funds'
          | 'tax_id'
          | 'logo'
          | 'accounts'
          | 'investments_dashboard'
          | 'address'
          | 'host'
          | 'asset_allocations'
          | 'stripe_customer_id'
          | 'external_contact_id'
          | 'config'
          | 'quarterlyInvestmentReportsMetaData'
          | 'business_description'
          | 'users'
      ) => {
        if (typeof this[key] !== 'undefined') {
          acc[key] = toJS(this[key])
        }

        return acc
      },
      {
        address: undefined,
        _id: undefined,
        isHost: undefined,
        isDisabled: undefined,
        showAnalytics: undefined,
        showNewReports: true,
        showOutreach: undefined,
        hideEvents: undefined,
        hideForms: undefined,
        hideInvestments: undefined,
        hideMembersByHost: undefined,
        hideMembersByOrg: undefined,
        hideMessages: undefined,
        hidePolls: undefined,
        hidePosts: undefined,
        hideTodos: undefined,
        legal_business_name: undefined,
        phone: undefined,
        source_of_funds: undefined,
        tax_id: undefined,
        logo: undefined,
        accounts: undefined,
        investments_dashboard: undefined,
        host: undefined,
        asset_allocations: undefined,
        stripe_customer_id: undefined,
        external_contact_id: undefined,
        config: undefined,
        quarterlyInvestmentReportsMetaData: undefined,
        business_description: undefined,
        users: [],
        groups: [],
        defaultPostId: this.isHost ? this.defaultPostId || '' : '',
      }
    )
    data.groups = this.groups
      .filter((g: OrgGroup) => g.id !== 'admins')
      .map((group: { data: unknown }) => group.data)
    data.address = toJS(this.address)

    if (data.users && data.users.length > 0 && this.host) {
      data.users = data.users.filter(u => !this.hostUsers.includes(u._id))
    }

    return data
  }

  // relating to the current user logged int
  get isOrgAdmin() {
    if (this.isImpersonating) {
      return this.members.some(
        (m: { _id: string; orgRole: string }) =>
          m._id === this.impersonatingId && m.orgRole === 'admin'
      )
    }
    return (
      this.isHostUser ||
      this.members.some(
        (m: { _id: string; orgRole: string }) =>
          m._id === this.rootStore.userStore._id && m.orgRole === 'admin'
      )
    )
  }

  get currentOrgRole() {
    if (this.isImpersonating) {
      const orgMember = this.members.find(
        member => member._id === this.impersonatingId
      )
      if (orgMember) {
        return orgMember.orgRole
      }

      return 'member'
    }

    if (
      this.isHostUser ||
      (this.rootStore.commonStore.token &&
        this.rootStore.commonStore.token.role &&
        this.rootStore.commonStore.token.role === 'admin')
    ) {
      return 'admin'
    }

    // TODO: this check might not be necessary
    if (this.rootStore.orgStore.currentOrg._id === this._id) {
      // if (this.isHostUser) {
      //   return 'admin'
      // }
      if (this.members && this.members.length > 0) {
        const orgMember = this.members.find(
          member => member._id === this.rootStore.userStore._id
        )
        if (orgMember) return orgMember.orgRole
      }
    }
    return 'disabled'
  }

  get isOrgContributor() {
    return this.currentOrgRole === 'contributor'
  }

  get isImpersonating() {
    return Boolean(this.impersonatingId)
  }

  get isImpersonatingAdmin() {
    return (
      this.isImpersonating &&
      this.members.some(
        m => m._id === this.impersonatingId && m.orgRole === 'admin'
      )
    )
  }

  get hideMembers() {
    return this.hideMembersByHost || this.hideMembersByOrg
  }

  get shouldShowMessages() {
    return (
      !this.hideMessages &&
      (!this.hideMembers ||
        this.isOrgAdmin ||
        (this.hostGroups && this.hostGroups.length > 0))
    )
  }

  handleAdminsGroup = () => {
    const adminGroup = this.groups.find((g: OrgGroup) => g.id === 'admins')
    const admins = this.members
      .filter(m => m.orgRole === 'admin' && !m.isHostAdmin)
      .map(a => a._id)

    if (adminGroup) {
      this.groups = this.groups.map((g: OrgGroup) => {
        if (g.id === 'admins') {
          g.members = admins
        }

        return g
      })
    } else {
      this.groups.unshift(
        new OrgGroup(this.rootStore, {
          id: 'admins',
          isGlobalGroup: false,
          isHostGroup: false,
          name: 'Administrators',
          members: admins,
        })
      )
    }
  }

  /**
   * Just a central method that can be called
   * If we need to do any mutating later
   */
  parseMembersResponse = (members: OrgMemberProps[]) => {
    const memberModels = observable.array(
      members.map(member => {
        if (typeof member.isHostAdmin === 'undefined') {
          member.isHostAdmin = member.isHostUser || false
        }

        if (typeof member.isHostUser === 'undefined') {
          member.isHostAdmin = member.isHostAdmin || false
        }

        return new OrgMember(this.rootStore, member)
      })
    )

    this.users = observable.array(
      members
        .filter(m => !m.isHostUser)
        .map(member => ({
          _id: member._id,
          role: member.initialOrgRole || member.orgRole || member.role,
          deletedAt: member.deletedAt,
          expiresAt: member.expiresAt,
          joinedAt: member.joinedAt || 0,
        }))
    )

    this.inactiveMembers = observable.array(
      memberModels.filter(m => m.isInactiveOrg)
    )
    this.members = observable.array(
      memberModels.filter(
        m => !this.inactiveMembers.some(mem => mem._id === m._id)
      )
    )

    this.handleAdminsGroup()

    return this.members
  }

  getMembers = async (force = false) => {
    this.loadingMembers = true

    if (this._id && (force || !this.members || this.members.length === 0)) {
      try {
        const { data } = await this.client.org.getMembers({
          id: this._id,
        })
        this.parseMembersResponse(data)
      } catch (e) {
        this.members.clear()
      }
    }

    this.loadingMembers = false

    return this.members
  }

  // note: this.members are Only Active members
  getMember = (memberId?: string): OrgMember => {
    if (memberId && this.members && this.members.length > 0) {
      const thisMember = this.members.find(member => member._id === memberId)

      if (thisMember) {
        return thisMember
      }
    }

    return new OrgMember(this.rootStore)
  }

  get memberCount() {
    return this.members && this.members.length > 0
      ? this.members.filter(m => !m.isHostAdmin && m.orgRole !== 'disabled')
          .length
      : Array.isArray(this.users) && this.users.length > 0 && this.users.filter
        ? this.users.filter(
            u =>
              u.role !== 'disabled' &&
              (this.host ? !this.hostUsers.includes(u._id) : true) &&
              !u.deletedAt
          ).length
        : 0
  }

  getStatusCount = (
    status: 'pending' | 'invited' | 'active' | 'inactive'
  ): number => {
    if (this.users.length === 0) {
      return 0
    }

    const isExpired = (member?: { expiresAt?: number }) =>
      Boolean(member?.expiresAt && member?.expiresAt < Date.now())

    return this.users.reduce((accumulator, currentMember) => {
      if (
        !currentMember.role.includes('disabled') &&
        !currentMember.deletedAt &&
        !isExpired(currentMember)
      ) {
        if (status !== 'active') {
          return accumulator + +currentMember.role.includes(status)
        }

        return accumulator + +(currentMember.role.split('-').length === 1)
      }

      if (status === 'inactive') {
        return accumulator + 1
      }

      return accumulator
    }, 0)
  }

  get invitedCount(): number {
    return this.getStatusCount('invited')
  }

  get pendingCount(): number {
    return this.getStatusCount('pending')
  }

  get activeCount(): number {
    return this.getStatusCount('active')
  }

  get inactiveCount(): number {
    return this.getStatusCount('inactive')
  }

  get totalActiveCount(): number {
    return this.pendingCount + this.invitedCount + this.activeCount
  }

  get memberSelectOptions() {
    return this.members
      .filter(member => member.orgRole !== 'disabled')
      .map(member => ({
        value: member._id,
        key: member._id,
        label: `${member.fname} ${member.lname}`,
      }))
  }

  getMemberRecords = async (force = false) => {
    if (this._id && (force || !this.memberRecords._id)) {
      try {
        const res = await this.client.org.getMemberRecords({
          _id: this._id,
        })
        this.memberRecords = new MemberRecords(this.rootStore, res.data)
      } catch (e) {
        this.memberRecords = new MemberRecords(this.rootStore, {
          org_id: this._id,
          properties: undefined,
        })
      }
    }
  }

  getMembersMemberRecord = (userId: string) => {
    const member = this.members && this.members.find(m => m._id === userId)
    if (member) {
      const memberRecord =
        this.memberRecords &&
        this.memberRecords.records.find(record => record.id === member._id)
      if (memberRecord) {
        return this.memberRecords.properties.reduce((acc, property) => {
          const recordProp =
            memberRecord.properties &&
            memberRecord.properties.find(
              (prop: MemberRecordsRecordProperty) => prop.id === property.id
            )
          acc[property.label] = recordProp ? recordProp.value : ''
          return acc
        }, {})
      }
    }
    return {}
  }

  getMembersMemberRecordAndProp = (
    memberId: string,
    memberRecordsFilter?: (properties: object) => boolean
  ) => {
    const memberRecord =
      this.memberRecords && this.memberRecords.getMemberRecord(memberId)
    if (!memberRecord) {
      return []
    }
    const memberRecordProps = memberRecord.properties
    const memberProps = memberRecordsFilter
      ? this.memberRecords.properties.filter(memberRecordsFilter)
      : this.memberRecords.properties

    return memberRecordProps.map(prop => {
      const recordProp = memberProps.find(mrp => mrp.id === prop.id) || {}
      return { recordProp, prop }
    })
  }

  registeringMember = false

  registeringMemberSuccess: string | undefined = undefined

  registeringMemberError: string | undefined = undefined

  registerMember = async (values: {
    fname: string
    lname: string
    email: string
    orgRole: string
    defaultRoles: string[]
    sendInvite: boolean
    groups: string[]
  }) => {
    try {
      const { fname, lname, email, orgRole, sendInvite, groups, defaultRoles } =
        values
      const { data } = await this.client.org.registerMember({
        id: this._id,
        fname,
        lname,
        email,
        role: orgRole,
        sendInvite,
        groups,
      })

      // add the user to the groups
      if (this.groups.length > 0) {
        this.groups.forEach(g => {
          if (groups.includes(g.id)) {
            g.members.push(data.userId)
          }
        })
      }

      // update members and member records
      await this.reloadData()
      await this.getMemberRecords(true)

      if (defaultRoles) {
        this.relationshipManagement.handleDefaultUserRoles(
          data.userId,
          defaultRoles
        )
      }

      log.code('org005', { data })
      return Promise.resolve('Member has been registered')
    } catch (e) {
      if (e.response.status === 400) {
        log.code('org202', { error: e.response })
        return Promise.reject(new Error('Please fill out all fields'))
      }
      log.code('org303')
      return Promise.reject(new Error('Could not register member'))
    }
  }

  updateAllPendingMemberDetails = async (
    member: { _id: string; groups: string[] },
    userData: {
      fname: string
      lname: string
      email: string
      orgRole: string
      groups: string[]
      defaultRoles: string[]
    },
    editFields: {
      updateRole: boolean
      updateGroups: boolean
      updateMains: boolean
      updateContacts: boolean
    },
    groupData: {
      addedGroups: string[]
      removedGroups: string[]
    }
  ) => {
    const { fname, lname, email, orgRole, groups, defaultRoles } = userData
    const { updateRole, updateGroups, updateMains, updateContacts } = editFields
    const { addedGroups, removedGroups } = groupData

    try {
      // update main fields
      if (updateMains) {
        await this.updateInvitedOrPendingMember({
          fname,
          lname,
          email,
          _id: member._id,
        })
      }

      // update role
      if (updateRole) {
        await this.changeMemberRole(member._id, orgRole)
      }

      // update Groups
      if (updateGroups) {
        if (groups && this.groups) {
          this.groups.forEach(g => {
            // add members to the groups if they are in the added array
            if (addedGroups.includes(g.id)) {
              g.addMember(member._id)
              return g
            }

            // remove member from group if they are in the absent array
            if (removedGroups.includes(g.id)) {
              g.removeMember(member._id)
              return g
            }

            return g
          })
          await this.saveGroups()
          member.groups = groups
        }
      }

      if (updateContacts) {
        this.relationshipManagement.handleDefaultUserRoles(
          member._id,
          defaultRoles
        )
      }

      // saving the groups saves the orgs
      // but if we are not saving the orgs we save
      // them here if we are updating contacts
      if (!updateGroups && updateContacts) {
        await this.save()
      } else {
        await this.reloadData()
      }

      return Promise.resolve(`Member details were sucessfully updated.`)
    } catch (e) {
      return Promise.reject(new Error('Error. Unable to update member details'))
    }
  }

  sendInviteLink = async (userId: string) => {
    try {
      const res = await this.client.org.sendInviteLink({
        id: this._id,
        userId,
      })
      await this.reloadData()
      await this.getMemberRecords(true)
      log.code('org006', { link: res.data })
      return true
    } catch (e) {
      log.code('org305', { error: e.response })
      throw e
    }
  }

  // callers:
  //  web/src/Model/Organization.ts
  //    updateAllPendingMemberDetails
  //  web/src/pages/Directory/Member/MemberActions
  //    updateMemberRecord
  // Server:
  //     url: `/orgs/v1/${data.org_id}/members/update-pending-or-invited`,
  //   method: 'PATCH',
  updateInvitedOrPendingMember = async (userData: {
    fname: string
    lname: string
    email: string
    _id: string
  }) => {
    if (
      !['fname', 'lname', 'email', '_id'].every(
        (key: string) => userData[key] && userData[key].length > 0
      )
    ) {
      throw new Error('All fields are required')
    }
    try {
      await this.client.org.updateInvitedOrPendingMember({
        org_id: this._id,
        ...userData,
      })
      return true
    } catch (e) {
      throw new Error('Unable to update user')
    }
  }

  getInviteLog = async () => {
    const res = await this.client.org.getInviteLog({
      id: this._id,
    })
    this.inviteLog = res.data || []
  }

  groupSaving = false

  groupSuccess: string | undefined = undefined

  saveGroups = async (): Promise<void> => {
    await this.save()
    await this.getMembers(true)
  }

  saveGroup = async (group: OrgGroup) => {
    group.error = ''
    group.name = group.name.trim()
    // Ensure unique name
    if (this.groups.some(g => g.name === group.name && g.id !== group.id)) {
      group.error = 'Group name already exists'
      return false
    }
    // If new, push
    if (!group.id) {
      group.id = generateID()
      this.groups.push(group)
    }

    // Save org record
    this.groupSaving = true
    this.groupSuccess = undefined
    try {
      await this.save()
      await this.getMembers(true)
      this.groupSuccess = 'Group saved successfully'
      this.groupSaving = false
      this.newGroup = new OrgGroup(this.rootStore, {})
      log.code('org004', { group })
      return true
    } catch (e) {
      group.error = 'Error saving group, please contact support'
      this.groupSaving = false
      log.code('org302', { error: e.response })
    }
    return false
  }

  handleGlobalGroups = () => {
    if (this.hostGlobalGroups && this.hostGlobalGroups.length > 0) {
      this.hostGlobalGroups.forEach(hgg => {
        if (!this.groups.some(g => g.id === hgg.id)) {
          this.groups.push(
            new OrgGroup(this.rootStore, {
              ...hgg,
              isGlobalGroup: true,
              members: [],
            })
          )
        }
      })
    }
  }

  reloadGroups = async () => {
    try {
      const { data } = await this.client.org.getOrgById(this._id)
      const adminGroup = new OrgGroup(this.rootStore, {
        id: 'admins',
        isGlobalGroup: false,
        isHostGroup: false,
        name: 'Administrators',
        members: this.members
          .filter(m => m.orgRole === 'admin' && !m.isHostAdmin)
          .map(a => a._id),
      })
      this.groups = data.groups
        ? [
            adminGroup,
            ...data.groups.map(
              (g: { id: string }) =>
                new OrgGroup(this.rootStore, {
                  ...g,
                  ...this.hostGlobalGroups.find(hgg => hgg.id === g.id),
                })
            ),
          ]
        : [adminGroup]
      this.handleGlobalGroups()
    } catch (e) {
      /* no-op */
    }
  }

  removeGroup = (id: string) => {
    this.groups = this.groups.map(g => {
      if (g.id === id) {
        g.deletedAt = Date.now()
      }
      return g
    })
    log.code('org003', { id })
    this.save()
  }

  restoreGroup = (id: string) => {
    this.groups = this.groups.map(g => {
      if (g.id === id) {
        g.deletedAt = 0
      }
      return g
    })
    log.code('org007', { id })
    this.save()
  }

  removeMember = async (userId: string, force = false) => {
    const { data } = await this.client.org.removeMember({
      _id: this._id,
      userId,
      force,
    })
    log.code('org002', { userId, force })
    return this.parseMembersResponse(data)
  }

  changeMemberExpiration = async (userId: string, expiresAt = 0) => {
    if (expiresAt && expiresAt <= +moment().startOf('day').format('x')) {
      throw new Error('The expiration must be after today')
    }
    try {
      const { data } = await this.client.org.changeMemberExpiration(this._id, {
        userId,
        expiresAt,
      })
      return this.parseMembersResponse(data)
    } catch (e) {
      throw new Error('Something went wrong. Please contact support')
    }
  }

  adminResetMemberCredentials = async (
    memberId: string,
    emails: string[],
    password: string
  ) => {
    try {
      await this.client.user.adminResetMemberCredentials(memberId, {
        emails,
        password,
      })
    } catch (e) {
      throw new Error('Something went wrong. Please contact support')
    }
  }

  changeMemberRole = async (userId: string, role = 'user') => {
    const member =
      this.members.find(m => m._id === userId) ||
      this.inactiveMembers.find(mem => mem._id === userId)
    const deletedOrExpired = member
      ? member.isExpired || member.isDeleted
      : false
    const currentRole =
      member.orgRole === 'disabled' && !deletedOrExpired
        ? member.initialOrgRole
        : member.orgRole
    const prefix = ['pending', 'invited'].includes(currentRole.split('-')[0])
      ? currentRole.split('-')[0]
      : false
    const newRole = (prefix ? `${prefix}-${role}` : role)
      .replace('pending-pending', 'pending')
      .replace('invited-invited', 'invited')

    if (!deletedOrExpired && newRole === currentRole) {
      return undefined
    }

    const reqData = {
      userId,
      role: newRole,
      expiresAt: 0,
      deletedAt: 0,
    }

    /**
     * If they are changing the role of an expired or deleted member
     * assume that they no longer want them expired
     */
    if (deletedOrExpired) {
      reqData.expiresAt = 0
      reqData.deletedAt = 0
    } else {
      delete reqData.expiresAt
      delete reqData.deletedAt
    }
    const { data } = await this.client.org.changeMemberRole(this._id, reqData)
    return this.parseMembersResponse(data)
  }

  loadingInvestmentsEmbeds = false

  loadingInvestmentsLooks = false

  investmentsEmbeds: string[] = []

  investmentDashboardsOpts = [
    { key: 0, value: 0, label: 'None' },
    { key: 9, value: 9, label: 'Current Asset Allocation' },
    { key: 10, value: 10, label: 'Current Balance' },
    { key: 16, value: 16, label: 'Asset Allocation w/ Targets' },
  ]

  getInvestmentsEmbeds = async () => {
    if (!this._id) return
    this.loadingInvestmentsLooks = true
    const embedIds = this.investments_dashboard
      .flat()
      .map(i => i.dashboard)
      .join(',')
    try {
      const res = await this.client.org.getInvestmentsEmbeds({
        org_id: this._id,
        embedIds,
      })
      this.investmentsEmbeds =
        res.data.map((item: { id: string; name: string }) => {
          const textItem = this.investmentDashboardsOpts.find(
            opt => +opt.value === +item.id
          )

          item.name = (textItem && textItem.label) || ''

          return item
        }) || []
    } catch (e) {
      if (e.response.status !== 404) {
        log.code('org304', { error: e.response })
      }
      this.investmentsEmbeds = []
    }
    this.loadingInvestmentsEmbeds = false
  }

  orgSaving = false

  orgSaveSuccess: boolean | string | undefined = undefined

  orgSaveError: boolean | string | undefined = undefined

  orgValidationErrors = {}

  impersonatingId: string | undefined = undefined

  save = async () => {
    this.orgSaving = true
    this.orgSaveSuccess = undefined
    this.orgSaveError = undefined
    try {
      const requestData = this.data
      requestData.groups = requestData.groups.filter(g => g.id !== 'admins')
      const { data } = await this.client.org.save(requestData)

      this.loadData(data)

      this.orgSaveSuccess = `Saved ${this.legal_business_name} successfully`
      this.orgSaving = false
      log.code('org001', { id: data._id })
      return true
    } catch (e) {
      if (e.response.status === 400 && Array.isArray(e.response.data)) {
        this.orgValidationErrors = e.response.data.reduce((acc, err) => {
          acc[err.key] = err.message
          return acc
        }, {})
        this.orgSaveError = 'Please ensure all fields are filled out correctly'
        log.code('org201', { error: e.response })
        return undefined
      }
      this.orgSaveError = `There was an error saving ${this.legal_business_name}. Please contact support`
      this.orgSaving = false
      log.code('org301', { error: e.response })
      return false
    }
  }

  saveOrgProfile = async (organizationState: this) => {
    organizationState.orgSaving = true
    organizationState.profileSuccess = false
    organizationState.profileError = false
    organizationState.profileMsg = undefined

    const { data } = organizationState

    try {
      const { data: resData } = await this.client.org.save(data)

      if (!data._id) {
        // New Orgs - push response to orgs, send to dashboard
        this.rootStore.orgStore.orgs.push(
          new Organization(this.rootStore, resData)
        )

        return this.rootStore.orgStore.orgs.find(org => org._id === resData._id)
      }

      this.rootStore.orgStore.orgs.replace(
        this.rootStore.orgStore.orgs.map(org => {
          if (org._id === this._id) {
            return this
          }
          return org
        })
      )

      organizationState.profileSuccess = true
      organizationState.profileMsg = `Sucessfully saved ${this.legal_business_name} profile`
      organizationState.validationErrors = {}
      organizationState.orgSaving = false
      log.code('org001', { id: resData._id })
    } catch (e) {
      organizationState.profileError = true
      organizationState.profileMsg = 'Error saving settings'
      if (
        e.response &&
        e.response.status === 400 &&
        Array.isArray(e.response.data)
      ) {
        organizationState.profileMsg =
          'Please make sure all required fields are filled out correctly.'
        organizationState.validationErrors = {}
        log.code('org201', { error: e.response })
      }
      log.code('org301', { error: e.response })
      organizationState.orgSaving = false
    }

    return this
  }

  get useHostInviteTemplate() {
    return (this.config && this.config.useHostInviteTemplate) || false
  }

  toggleUseHostInviteTemplate = () => {
    this.config.useHostInviteTemplate = !this.config.useHostInviteTemplate
  }

  get inviteTemplate() {
    return (this.config && this.config.inviteTemplate) || ''
  }

  get themeProperties() {
    return this.config && this.config.themeProperties
  }

  get latestSignups() {
    if (!this.users?.length) {
      return []
    }
    const last30d = Date.now() * 1000 * 60 * 60 * 24 * 30
    return this.users
      .filter(u => u.joinedAt && u.joinedAt < last30d)
      .map(u => {
        const memberRecord = this.members.find(m => m._id === u._id)
        if (memberRecord) {
          return {
            name: `${memberRecord.fname} ${memberRecord.lname}`,
            organization: this.legal_business_name,
            when: moment(u.joinedAt).fromNow(),
            joinedAt: u.joinedAt,
          }
        }
        return undefined
      })
      .filter(u => Boolean(u))
      .sort((a, b) => b.joinedAt - a.joinedAt)
  }

  saveInviteTemplate = async (inviteTemplate: string) => {
    await this.client.org.saveInviteTemplate(this._id, {
      inviteTemplate: inviteTemplate || '',
      useHostInviteTemplate: toJS(this.config.useHostInviteTemplate),
    })
    this.config.inviteTemplate = inviteTemplate
    return true
  }

  annualInvestmentSummary: {
    [key: string]: IObservableArray<InvestmentSummary>
  } = {}

  getAnnualInvestmentSummary = async (asOfDate: string) => {
    if (this._id && !this.annualInvestmentSummary[asOfDate]) {
      this.annualInvestmentSummary[asOfDate] = observable.array([])

      try {
        const { data } = await this.client.org.getAnnualInvestmentSummary(
          this._id,
          asOfDate ? { asOfDate } : undefined
        )
        this.annualInvestmentSummary[asOfDate] = observable.array(data)
      } catch (e) {
        // no-op
      }
    }

    return this.annualInvestmentSummary[asOfDate]?.toJSON() || []
  }

  inventmentBondHoldings: { [key: string]: [] } = {}

  getInvestmenBondHoldings = async (asOfDate: string) => {
    if (this._id && !this.inventmentBondHoldings[asOfDate]) {
      this.inventmentBondHoldings[asOfDate] = []

      try {
        const { data } = await this.client.org.getInvestmenBondHoldings(
          this._id,
          asOfDate ? { asOfDate } : undefined
        )
        this.inventmentBondHoldings[asOfDate] = data
      } catch (e) {
        // no-op
      }
    }

    return this.inventmentBondHoldings[asOfDate]
  }

  historicInvestmentSummary: {
    [key: string]: IObservableArray<InvestmentSummary>
  } = {}

  getHistoricInvestmentSummary = async (asOfDate: string) => {
    if (this._id && !this.historicInvestmentSummary[asOfDate]) {
      this.historicInvestmentSummary[asOfDate] = observable.array([])

      try {
        const { data } = await this.client.org.getHistoricInvestmentSummary(
          this._id,
          asOfDate ? { asOfDate } : undefined
        )
        this.historicInvestmentSummary[asOfDate] = observable.array(data)
      } catch (e) {
        // no-op
      }
    }

    return this.historicInvestmentSummary[asOfDate].toJSON()
  }

  inceptionInvestmentSummary: {
    [key: string]: IObservableArray<InvestmentSummary>
  } = {}

  getInceptionInvestmentSummary = async (asOfDate: string) => {
    if (
      this._id &&
      this.showInvestmentsInceptionPerformanceChart &&
      !this.inceptionInvestmentSummary[asOfDate]
    ) {
      this.inceptionInvestmentSummary[asOfDate] = observable.array([])

      try {
        const { data } = await this.client.org.getInceptionInvestmentSummary(
          this._id,
          asOfDate ? { asOfDate } : undefined
        )
        this.inceptionInvestmentSummary[asOfDate] = observable.array(data)
      } catch (e) {
        // no-op
      }
    }

    return this.inceptionInvestmentSummary[asOfDate]?.toJSON() || []
  }

  investmentAccounts: unknown[] = []

  getInvestmentAccounts = async () => {
    if (this._id && this.investmentAccounts.length === 0) {
      const { data } = await this.client.org.getInvestmentAccounts(
        this._id,
        undefined
      )

      this.investmentAccounts = data
    }
    return this.investmentAccounts ? toJS(this.investmentAccounts) : []
  }

  investmentHouseholds: { [key: string]: InvestmentHousehold[] } = {}

  getInvestmentHouseholds = async (asOfDate: string | undefined) => {
    const asOfDateToUse = asOfDate || moment().format('YYYY-MM-DD')

    if (this._id && !this.investmentHouseholds[asOfDateToUse]) {
      const { data } = await this.client.org.getInvestmentHouseholds(this._id, {
        asOfDate: asOfDateToUse,
      })

      this.investmentHouseholds[asOfDateToUse] = data
    }

    return this.investmentHouseholds[asOfDateToUse]
      ? toJS(this.investmentHouseholds[asOfDateToUse])
      : []
  }

  investmentLastPositionDate: string | undefined = undefined

  getInvestmentLastPositionDate = async () => {
    if (this._id && !this.investmentLastPositionDate) {
      const { data } = await this.client.org.getInvestmentLastPositionDate(
        this._id,
        { t: Date.now() }
      )
      this.investmentLastPositionDate = data
    }

    return this.investmentLastPositionDate
  }

  investmentHouseholdPerformance: {
    startDate: string
    endDate: string
    data: IObservableArray<InvestmentHouseholdsPerformance>
  }[] = []

  getInvestmentHouseholdPerformance = async (
    startDate: string,
    endDate: string
  ) => {
    if (this._id) {
      const alreadyFetched = this.investmentHouseholdPerformance.find(
        performance =>
          performance.startDate === startDate && performance.endDate === endDate
      )

      if (alreadyFetched) {
        return alreadyFetched.data.toJSON()
      }

      const { data } = await this.client.org.getInvestmentHouseholdPerformance(
        this._id,
        startDate && endDate ? { endDate, startDate } : undefined
      )

      this.investmentHouseholdPerformance.push({
        startDate,
        endDate,
        data: observable.array(data),
      })

      return this.investmentHouseholdPerformance
        .find(
          performance =>
            performance.startDate === startDate &&
            performance.endDate === endDate
        )
        .data.toJSON()
    }

    return undefined
  }

  investmentHistoricPerformance: { [key: string]: [] } = {}

  getInvestmentHistoricPerformance = async (asOfDate: string) => {
    if (this._id && !this.investmentHistoricPerformance[asOfDate]) {
      this.investmentHistoricPerformance[asOfDate] = []

      try {
        const { data } = await this.client.org.getInvestmentHistoricPerformance(
          this._id,
          asOfDate ? { asOfDate } : undefined
        )
        this.investmentHistoricPerformance[asOfDate] = data
      } catch (e) {
        // no-op
      }
    }

    return this.investmentHistoricPerformance[asOfDate]
  }

  investmentSPYBreakdown: { [key: string]: object } = {}

  getInvestmentSPYBreakdown = async (asOfDate: string) => {
    if (this._id && !this.investmentSPYBreakdown[asOfDate]) {
      this.investmentSPYBreakdown[asOfDate] = {}

      try {
        const { data } = await this.client.org.getInvestmentSPYBreakdown(
          this._id,
          asOfDate ? { asOfDate } : undefined
        )
        this.investmentSPYBreakdown[asOfDate] = data
      } catch (e) {
        // no-op
      }
    }

    return this.investmentSPYBreakdown[asOfDate]
  }

  investmentTransactions: {
    startDate: string
    endDate: string
    data: IObservableArray<[]>
  }[] = []

  getInvestmentTransactions = async (startDate: string, endDate: string) => {
    if (this._id) {
      const alreadyFetched = this.investmentTransactions.find(
        transactions =>
          transactions.startDate === startDate &&
          transactions.endDate === endDate
      )

      if (alreadyFetched) {
        return alreadyFetched.data.toJSON()
      }

      try {
        const { data } = await this.client.org.getInvestmentTransactions(
          this._id,
          {
            endDate,
            startDate,
          }
        )

        this.investmentTransactions.push({
          startDate,
          endDate,
          data: observable.array(data),
        })

        return this.investmentTransactions
          .find(
            transactions =>
              transactions.startDate === startDate &&
              transactions.endDate === endDate
          )
          .data.toJSON()
      } catch (e) {
        // no-op
      }
    }
    return undefined
  }

  investmentExpenses: {
    startDate: string
    endDate: string
    data: IObservableArray<[]>
  }[] = []

  getInvestmentExpenses = async (startDate: string, endDate: string) => {
    if (this._id) {
      const alreadyFetched = this.investmentExpenses.find(
        expense =>
          expense.startDate === startDate && expense.endDate === endDate
      )

      if (alreadyFetched) {
        return alreadyFetched.data.toJSON()
      }

      try {
        const { data } = await this.client.org.getInvestmentExpenses(this._id, {
          endDate,
          startDate,
        })

        this.investmentExpenses.push({
          startDate,
          endDate,
          data: observable.array(data),
        })

        return this.investmentExpenses
          .find(
            expense =>
              expense.startDate === startDate && expense.endDate === endDate
          )
          .data.toJSON()
      } catch (e) {
        // no-op
      }
    }
    return undefined
  }

  investmentIncomes: {
    startDate: string
    endDate: string
    data: IObservableArray<[]>
  }[] = []

  getInvestmentIncomes = async (startDate: string, endDate: string) => {
    if (this._id) {
      const alreadyFetched = this.investmentIncomes.find(
        expense =>
          expense.startDate === startDate && expense.endDate === endDate
      )

      if (alreadyFetched) {
        return alreadyFetched.data.toJSON()
      }

      try {
        const { data } = await this.client.org.getInvestmentIncomes(this._id, {
          endDate,
          startDate,
        })

        this.investmentIncomes.push({
          startDate,
          endDate,
          data: observable.array(data),
        })

        return this.investmentIncomes
          .find(
            incomes =>
              incomes.startDate === startDate && incomes.endDate === endDate
          )
          .data.toJSON()
      } catch (e) {
        // no-op
      }
    }
    return undefined
  }

  investmentBenchmarks: { [key: string]: [] } = {}

  getInvestmentBenchmarks = async (asOfDate: string) => {
    if (this._id && !this.investmentBenchmarks[asOfDate]) {
      this.investmentBenchmarks[asOfDate] = []

      try {
        const { data } = await this.client.org.getInvestmentBenchmarks(
          this._id,
          asOfDate ? { asOfDate } : undefined
        )
        this.investmentBenchmarks[asOfDate] = data
      } catch (e) {
        // no-op
      }
    }

    return this.investmentBenchmarks[asOfDate]
  }

  investmentSummaries: {
    startDate: string
    endDate: string
    data: IObservableArray<InvestmentSummary>
  }[] = []

  getInvestmentSummaryByRange = async (
    startDate: string,
    endDate: string
  ): Promise<InvestmentSummary[] | undefined> => {
    if (this._id) {
      const alreadyFetched = this.investmentSummaries.find(
        summary =>
          summary.startDate === startDate && summary.endDate === endDate
      )

      if (alreadyFetched) {
        return alreadyFetched.data.toJSON()
      }

      try {
        const { data } = await this.client.org.getInvestmentSummaryByRange(
          this._id,
          { startDate, endDate }
        )

        this.investmentSummaries.push({
          startDate,
          endDate,
          data: observable.array(data),
        })

        return this.investmentSummaries
          .find(
            summary =>
              summary.startDate === startDate && summary.endDate === endDate
          )
          .data.toJSON()
      } catch (e) {
        // no-op
      }
    }
    return undefined
  }

  investmentBalances: unknown[] | undefined = undefined

  getInvestmentBalances = async () => {
    if (this._id && !this.investmentBalances) {
      try {
        const { data } = await this.client.org.getInvestmentBalances(this._id)
        this.investmentBalances = data
      } catch (e) {
        // no-op
      }
    }

    return toJS(this.investmentBalances)
  }

  investmentBalancesByAccount: unknown[] | undefined = undefined

  getInvestmentBalancesByAccount = async () => {
    if (this._id && !this.investmentBalancesByAccount) {
      try {
        const { data } = await this.client.org.getInvestmentBalancesByAccount(
          this._id
        )
        this.investmentBalancesByAccount = data
      } catch (e) {
        // no-op
      }
    }

    return toJS(this.investmentBalancesByAccount)
  }

  investmentBuySellByAccount: unknown[] | undefined = undefined

  getInvestmentBuySellByAccount = async () => {
    if (this._id && !this.investmentBuySellByAccount) {
      try {
        const { data } = await this.client.org.getInvestmentBuySellByAccount(
          this._id
        )
        this.investmentBuySellByAccount = data
      } catch (e) {
        // no-op
      }
    }

    return toJS(this.investmentBuySellByAccount)
  }

  investmentHistoricBalances: { [key: string]: [] } = {}

  getInvestmentHistoricBalances = async (asOfDate: string) => {
    if (this._id && !this.investmentHistoricBalances[asOfDate]) {
      this.investmentHistoricBalances[asOfDate] = []

      try {
        const { data } = await this.client.org.getInvestmentHistoricBalances(
          this._id,
          asOfDate ? { asOfDate } : undefined
        )
        this.investmentHistoricBalances[asOfDate] = data
      } catch (e) {
        // no-op
      }
    }

    return this.investmentHistoricBalances[asOfDate]
  }

  investmentHouseholdHoldings: { [key: string]: [] } = {}

  getInvestmentHouseholdHoldings = async (asOfDate: string) => {
    if (this._id && !this.investmentHouseholdHoldings[asOfDate]) {
      this.investmentHouseholdHoldings[asOfDate] = []

      try {
        const { data } = await this.client.org.getInvestmentHouseholdHoldings(
          this._id,
          asOfDate ? { asOfDate } : undefined
        )
        this.investmentHouseholdHoldings[asOfDate] = data
      } catch (e) {
        // no-op
      }
    }

    return this.investmentHouseholdHoldings[asOfDate]
  }

  investmentProjectedIncome: { [key: string]: InvestmentProjectedIncome } = {}

  getInvestmentProjectedIncome = async (asOfDate: string) => {
    if (this._id && !this.investmentProjectedIncome[asOfDate]) {
      this.investmentProjectedIncome[asOfDate] = { asOfDate, data: [] }

      try {
        const { data } = await this.client.org.getInvestmentProjectedIncome(
          this._id,
          asOfDate ? { asOfDate } : undefined
        )
        this.investmentProjectedIncome[asOfDate] = data
      } catch (e) {
        // no-op
      }
    }

    return this.investmentProjectedIncome[asOfDate]
  }

  investmentTargetAllocations: { [key: string]: [] } = {}

  getInvestmentTargetAllocations = async (asOfDate?: string) => {
    const actualAsOfDate = asOfDate || moment().format('YYYY-MM-DD')
    if (this._id && !this.investmentTargetAllocations[actualAsOfDate]) {
      try {
        const { data } = await this.client.org.getInvestmentTargetAllocations(
          this._id,
          {
            asOfDate: actualAsOfDate,
          }
        )
        this.investmentTargetAllocations[actualAsOfDate] = data
      } catch (e) {
        // no-op
      }
    }

    return toJS(this.investmentTargetAllocations[actualAsOfDate])
  }

  investmentAssetAdjustments: unknown[] | undefined = undefined

  getInvestmentAssetAdjustments = async () => {
    if (this._id && !this.investmentAssetAdjustments) {
      try {
        const { data } = await this.client.org.getInvestmentAssetAdjustments(
          this._id,
          undefined
        )
        this.investmentAssetAdjustments = data
      } catch (e) {
        // no-op
      }
    }

    return toJS(this.investmentAssetAdjustments)
  }

  getEvestechInvestmentInvoices = async (data?: object) => {
    if (this._id) {
      try {
        const { data: resData } = await this.client.org.getEvestechBillings(
          this._id,
          data || {}
        )
        return resData
      } catch (e) {
        // no-op
      }
    }
    return undefined
  }

  getInvestmentInvoices = async (asOfDate?: string) => {
    if (this._id) {
      try {
        const { data } = await this.client.org.getBillings(
          this._id,
          asOfDate ? { asOfDate } : undefined
        )
        return data
      } catch (e) {
        // no-op
      }
    }
    return undefined
  }

  investmentPositions: { [key: string]: [] } = {}

  getInvestmentPositions = async (asOfDate: string) => {
    if (this._id && !this.investmentPositions[asOfDate]) {
      this.investmentPositions[asOfDate] = []

      try {
        const { data } = await this.client.org.getInvestmentPositions(
          this._id,
          asOfDate ? { asOfDate } : undefined
        )
        this.investmentPositions[asOfDate] = data
      } catch (e) {
        // no-op
      }
    }

    return this.investmentPositions[asOfDate]
  }

  getInvestmentAUM = async (asOfDate?: string) => {
    if (this._id && this.isHost) {
      try {
        const { data } = await this.client.org.getInvestmentAUM(
          this._id,
          asOfDate ? { asOfDate } : undefined
        )
        return data
      } catch (e) {
        // no-op
      }
    }
    return []
  }

  get externalCRMProvider() {
    return this.config?.externalCRM?.provider
  }

  get externalCRMProviderDisplayName(): string {
    return ExternalCRMOptions[this.externalCRMProvider] || 'No Provider'
  }

  getCRMEvents = async () => {
    if (
      this._id &&
      this.isHost &&
      this.externalCRMProvider &&
      (!this.crmEvents || this.crmEvents.length === 0)
    ) {
      try {
        const { data } = await this.client.crm.getEvents(this._id)

        if (data && data.length > 0) {
          this.crmEvents = data
        }
      } catch (e) {
        // no-op
      }
    }

    return toJS(this.crmEvents)
  }

  getCRMTasks = async () => {
    if (
      this._id &&
      this.isHost &&
      this.externalCRMProvider &&
      (!this.crmTasks || this.crmTasks.length === 0)
    ) {
      try {
        const { data } = await this.client.crm.getTasks(this._id)

        if (data && data.length > 0) {
          this.crmTasks = data
        }
      } catch (e) {
        // no-op
      }
    }

    return toJS(this.crmTasks)
  }

  firmDonatedSecurities: IObservableArray<InvestmentDonatedSecurity> =
    observable.array([])

  getFirmDonatedSecurities = async () => {
    if (
      this._id &&
      this.isHost &&
      this.canShowFirmInvestments &&
      this.performanceReportingProviderDisplayName === 'eVestech'
    ) {
      const { data } = await this.client.org.getFirmDonatedSecurities(
        this._id,
        {
          endDate: moment().subtract(1, 'day').format('YYYY-MM-DD'),
          startDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
        }
      )

      if (data) {
        this.firmDonatedSecurities = observable.array(
          data.sort(
            (a: InvestmentDonatedSecurity, b: InvestmentDonatedSecurity) =>
              moment(b.tradeDate).valueOf() - moment(a.tradeDate).valueOf()
          )
        )
      }
    }

    return toJS(this.firmDonatedSecurities)
  }

  getInvestmentFirmHoldings = async (requestData?: {
    asOfDate?: string
    page?: number
    perPage?: number
    search?: object
    sort?: object[]
  }) => {
    if (this._id && this.isHost) {
      try {
        const { data } = await this.client.org.getInvestmentFirmHoldings(
          this._id,
          requestData
        )
        return data
      } catch (e) {
        // no-op
      }
    }
    return []
  }

  investmentFirmRevenues: IObservableArray<InvestmentFirmRevenue> =
    observable.array([])

  getInvestmentFirmRevenues = async () => {
    if (this._id && this.isHost && this.investmentFirmRevenues.length === 0) {
      try {
        const { data } = await this.client.org.getInvestmentFirmRevenues(
          this._id,
          { v: Date.now() }
        )
        if (data?.length > 0) {
          this.investmentFirmRevenues = observable.array(data)
        }
      } catch (e) {
        // no-op
      }
    }

    return this.investmentFirmRevenues
  }

  firmAccountBalancesWithCash: object[] = []

  getFirmAccountBalancesWithCash = async (query?: object) => {
    if (
      this._id &&
      this.isHost &&
      (query || this.firmAccountBalancesWithCash.length === 0)
    ) {
      try {
        const { data } =
          await this.client.org.getInvestmentAccountBalancesWithCash(
            this._id,
            query
          )

        this.firmAccountBalancesWithCash = data
      } catch (e) {
        // no-op
      }
    }

    return toJS(this.firmAccountBalancesWithCash)
  }

  firmSummary: IObservableArray<FirmInvestmentSummary> = observable.array([])

  getInvestmentFirmSummary = async (query: object) => {
    if (this._id && this.isHost && this.firmSummary.length === 0) {
      try {
        const { data } = await this.client.org.getInvestmentFirmSummary(
          this._id,
          query
        )

        this.firmSummary = observable.array(data)
      } catch (e) {
        // no-op
      }
    }

    return this.firmSummary
  }

  getSavedQuarterlyInvestmentReports = async (): Promise<{
    data: number[]
  }> => {
    if (this._id && this.isHost) {
      const { data } = await this.client.org.getSavedQuarterlyInvestmentReports(
        this._id,
        {}
      )

      return {
        data: data.map((d: string | number) => +d),
      }
    }

    return { data: [] }
  }

  saveQuarterlyInvestmentReports = async (data: { quarterEnding: string }) => {
    if (this._id && this.isHost) {
      return this.client.org.saveQuarterlyInvestmentReports(this._id, data)
    }

    return {}
  }

  resetInvestments = () => {
    this.investmentAccounts = []
    this.investmentBalances = undefined
    this.investmentBalancesByAccount = undefined
    this.investmentBuySellByAccount = undefined
    this.investmentFirmRevenues = []
    this.investmentHouseholds = undefined
    this.investmentLastPositionDate = undefined
    this.investmentTargetAllocations = undefined
    this.investmentAssetAdjustments = undefined
    this.annualInvestmentSummary = {}
    this.historicInvestmentSummary = {}
    this.inceptionInvestmentSummary = {}
    this.investmentBenchmarks = {}
    this.inventmentBondHoldings = {}
    this.investmentExpenses = []
    this.investmentHistoricBalances = {}
    this.investmentHistoricPerformance = {}
    this.investmentHouseholdHoldings = {}
    this.investmentHouseholdPerformance = []
    this.investmentIncomes = []
    this.investmentPositions = {}
    this.investmentProjectedIncome = {}
    this.investmentSPYBreakdown = {}
    this.investmentSummaries = []
    this.investmentTransactions = []
  }

  get showInvestmentReports() {
    if (
      this.hideInvestments ||
      (this.isHost ? false : !this.accounts || this.accounts.length === 0)
    ) {
      return false
    }
    const userId = this.impersonatingId || this.rootStore?.userStore?._id
    const member = this.members.find(m => m._id === userId)
    return (
      this.isHost ||
      (member && member.isHostUser) ||
      this.groups.some((g: OrgGroup) => {
        if (g.isGlobalGroup && g.members.includes(userId)) {
          const gg = this.hostGlobalGroups.find(hgg => hgg.id === g.id)
          return gg && gg.allowInvestmentReports
        }
        return false
      })
    )
  }

  get allowInvestmentsDateRange() {
    return (
      this.showInvestmentReports &&
      this.config.performanceReporting.provider !== 'bridge' &&
      this.config.performanceReporting.allowDateRange
    )
  }

  get showInvestmentsAllocationByClass() {
    return (
      this.showInvestmentReports &&
      this.config.performanceReporting.reports.includes('allocation-by-class')
    )
  }

  get showInvestmentsAllocationBySector() {
    return (
      this.showInvestmentReports &&
      this.config.performanceReporting.reports.includes('allocation-by-sector')
    )
  }

  get showInvestmentsAllocationDetail() {
    return (
      this.showInvestmentReports &&
      this.config.performanceReporting.reports.includes('allocation-detail')
    )
  }

  get showInvestmentsAllocationTargets() {
    return (
      this.showInvestmentReports &&
      this.config.performanceReporting.reports.includes('allocation-targets')
    )
  }

  get showInvestmentsBenchmarks() {
    return (
      this.showInvestmentReports &&
      this.config.performanceReporting.reports.includes('benchmarks')
    )
  }

  get showInvestmentsBondHoldings() {
    return (
      this.showInvestmentReports &&
      this.config.performanceReporting.reports.includes('bond-holdings')
    )
  }

  get showInvestmentsHistoricalSummary() {
    return (
      this.showInvestmentReports &&
      this.config.performanceReporting.reports.includes('historical-summary')
    )
  }

  get showInvestmentsProjectedIncome() {
    return (
      this.showInvestmentReports &&
      this.config.performanceReporting.reports.includes('projected-income')
    )
  }

  get showInvestmentsPerformanceTable() {
    return (
      this.showInvestmentReports &&
      this.config.performanceReporting.reports.includes('performance')
    )
  }

  get showInvestmentsAnnualPerformanceChart() {
    return (
      this.showInvestmentReports &&
      this.config.performanceReporting.reports.includes(
        'performance-line-annual'
      )
    )
  }

  get showInvestmentsInceptionPerformanceChart() {
    return (
      this.showInvestmentReports &&
      this.config.performanceReporting.reports.includes(
        'performance-line-inception'
      )
    )
  }

  get showInvestmentsStrategyPerformance() {
    return (
      this.showInvestmentReports &&
      this.config.performanceReporting.reports.includes('strategy-performance')
    )
  }

  get showInvestmentsSummary() {
    return (
      this.showInvestmentReports &&
      this.config.performanceReporting.reports.includes('summary')
    )
  }

  get showInvestmentBilling(): boolean {
    return (
      this.isHost &&
      !this.hideInvestmentBilling &&
      !this.isImpersonating &&
      this.currentOrgRole === 'admin'
    )
  }

  get showInvestmentInvoicing(): boolean {
    if (this.hideInvestments || !this.accounts || this.accounts.length === 0) {
      return false
    }
    const userId = this.impersonatingId || this.rootStore?.userStore?._id
    const member = this.members.find(m => m._id === userId)
    if (member && member.isHostUser) {
      return true
    }
    return (
      this.config &&
      this.config.showInvestmentInvoicing &&
      this.groups.some(g => {
        if (g.isGlobalGroup && g.members.includes(userId)) {
          const gg = this.hostGlobalGroups.find(hgg => hgg.id === g.id)
          return gg && gg.allowInvestmentInvoicing
        }
        return false
      })
    )
  }

  get performanceReportingProvider() {
    return this.config?.performanceReporting?.provider
  }

  get performanceReportingProviderDisplayName(): string {
    return (
      PerformanceProviderOptions[this.performanceReportingProvider] ||
      'No Provider'
    )
  }

  get canShowFirmInvestments(): boolean {
    return (
      this.isHost &&
      !this.hideInvestments &&
      (this.performanceReportingProviderDisplayName === 'Addepar' ||
        this.performanceReportingProviderDisplayName === 'eVestech')
    )
  }

  get hasGlobalGroups(): boolean {
    return (
      !this.isHost && this.hostGlobalGroups && this.hostGlobalGroups.length > 0
    )
  }

  get isHostWithGlobalGroups(): boolean {
    return (
      this.isHost &&
      this.config &&
      this.config.globalGroups &&
      this.config.globalGroups.length > 0
    )
  }

  get displayName(): string {
    return 'Client'
  }

  resources: ModelBase[] | undefined = undefined

  getResources = async () => {
    this.resources = this.rootStore.allItems
    return this.resources
  }

  convertHtmlToPdf = async (request: object) => {
    return this.client.org.convertHtmlToPdf(this._id, request)
  }

  getMemberDetailsById(id: string): { id: string; name: string } {
    const member = this.members.find(m => m._id === id)

    if (!member) {
      return null
    }
    return {
      ...member,
      id: member._id,
      name: `${member.fname} ${member.lname}`,
    }
  }

  contactManagement: ContactManagement = undefined

  getContactManagement = async () => {
    const { data } = await this.rootStore.client.contactManagement.get(this._id)
    this.contactManagement = new ContactManagement(this.rootStore, data)
  }

  relationshipManagement: RelationshipManagement = undefined

  getRelationshipManagement = async () => {
    const { data } = await this.rootStore.client.relationshipManagement.get(
      this._id
    )

    this.relationshipManagement = new RelationshipManagement(
      this.rootStore,
      data
    )
  }

  getGlobalGroupChildData = async () => {
    const { data } = await this.client.org.getGlobalGroupsAndMembers(this._id)

    if (data && data.length > 0) {
      return data
    }

    return []
  }

  get showAssistance(): boolean {
    return (
      !this.isHost &&
      this.contactManagement &&
      this.relationshipManagement &&
      this.relationshipManagement.assignments.some(
        assigned => assigned.org_id === this._id && assigned.users.length > 0
      ) &&
      (this.contactManagement.callsToAction.length > 0 ||
        this.contactManagement.contactRoles.length > 0)
    )
  }
}
