import queryString from 'query-string'
import APIResponseType from './APIResponseType'

export const fetchAllItems = (
  org_id: string,
  impersonatingId?: string
): APIResponseType => ({
  url: `/orgs/v1/proxy/${org_id}${
    impersonatingId ? `/impersonating/${impersonatingId}` : ''
  }`,
  method: 'GET',
})

export const getGlobalGroupsAndMembers = (org_id: string): APIResponseType => ({
  url: `/orgs/v1/${org_id}/global_group_members`,
  method: 'GET',
})
export const getMemberOrgPermissionedResources = (
  orgId: string
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/member_resources`,
  method: 'GET',
})

export const getOrgs = (): APIResponseType => ({
  url: '/orgs/v1/',
  method: 'GET',
})

export const getOrgById = (id: string): APIResponseType => ({
  url: `/orgs/v1/${id}`,
  method: 'GET',
})

export const getOrgsFiltered = (data: object): APIResponseType => ({
  url: '/orgs/v1/filter',
  method: 'POST',
  data,
})

export const getOrgsFilteredByHost = (
  id: string,
  impersonatingId = ''
): APIResponseType => ({
  url: `/orgs/v1/${id}/filter${
    impersonatingId ? `?impersonatingId=${impersonatingId}` : ''
  }`,
  method: 'GET',
})

export const getAvailableOrgs = (impersonatingId = ''): APIResponseType => ({
  url: `/orgs/v1/available${
    impersonatingId ? `?impersonatingId=${impersonatingId}` : ''
  }`,
  method: 'GET',
})

export const getAllOrgsMembers = (): APIResponseType => ({
  url: '/orgs/v1/members',
  method: 'GET',
})

export const getMembers = (data: { id: string }): APIResponseType => ({
  url: `/orgs/v1/${data.id}/members`,
  method: 'GET',
})

export const registerMember = (data: {
  id: string
  fname?: string
  lname?: string
  email?: string
  role?: string
  sendInvite?: boolean
  groups?: string[]
}): APIResponseType => ({
  url: `/orgs/v1/${data.id}/members/register`,
  method: 'POST',
  data,
})

export const updateInvitedOrPendingMember = (data: {
  org_id: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/members/update-pending-or-invited`,
  method: 'PATCH',
  data,
})

export const sendInviteLink = (data: {
  id: string
  userId: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.id}/members/invite`,
  method: 'POST',
  data, // { userId } >> inviteLink
})

export const getInviteLog = (data: { id: string }): APIResponseType => ({
  url: `/orgs/v1/${data.id}/members/invite-log`,
  method: 'GET',
})

export const joinWithInvite = (data: {
  token: string
  deviceHash: string
  deviceInfo: object
}): APIResponseType => ({
  url: '/orgs/v1/join',
  method: 'POST',
  data,
})
// >> { autologintoken:string, newUserSignup:boolean }

export const changeMemberRole = (
  id: string,
  data: object
): APIResponseType => ({
  url: `/orgs/v1/${id}/members/role`,
  method: 'PATCH',
  data,
})

export const changeMemberExpiration = (
  id: string,
  data: object
): APIResponseType => ({
  url: `/orgs/v1/${id}/members/expiration`,
  method: 'PATCH',
  data,
})

export const removeMember = (data: {
  _id: string
  userId: string
  force: boolean
}): APIResponseType => ({
  url: `/orgs/v1/${data._id}/members/remove`,
  method: 'PATCH',
  data: { userId: data.userId, force: data.force },
})

export const getMemberRecords = (data: { _id: string }): APIResponseType => ({
  url: `/orgs/v1/${data._id}/members/records`,
  method: 'GET',
})

export const saveMemberRecords = (data: {
  org_id: string
  properties?: object[]
  records?: object[]
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/members/records`,
  method: 'PATCH',
  data,
})

export const save = (data: { _id?: string }): APIResponseType => ({
  url: `/orgs/v1/${data._id ? data._id : ''}`,
  method: data._id ? 'PATCH' : 'POST',
  data,
})

export const saveAssignedSurvey = (data: {
  org_id: string
  _id: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/assigned-surveys/${data._id ? data._id : ''}`,
  method: data._id ? 'PATCH' : 'POST',
  data,
})

export const getAssignedSurveys = (data: {
  org_id: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/assigned-surveys`,
  method: 'GET',
})

export const notifyAssignedSurvey = (data: {
  org_id: string
  _id: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/assigned-surveys/${data._id}/notify`,
  method: 'PATCH',
})

export const getInvestmentsEmbeds = (data: {
  org_id: string
  embedIds: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/looker?embeds=${data.embedIds}`,
  method: 'GET',
})

export const getAllMetaData = (data: { org_id: string }): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/meta-data`,
  method: 'GET',
})

export const getMetaData = (data: {
  org_id: string
  key: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/meta-data/${data.key}`,
  method: 'GET',
})

export const saveMetaData = (data: {
  org_id: string
  key: string
}): APIResponseType => ({
  url: `/orgs/v1/${data.org_id}/meta-data/${data.key}`,
  method: 'PATCH',
  data,
})

export const saveInviteTemplate = (
  orgId: string,
  data: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/members/invite-template`,
  method: 'PUT',
  data,
})

export const convertHtmlToPdf = (
  orgId: string,
  data: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/htmlToPDF`,
  method: 'POST',
  data,
  timeout: 180000,
})

// Investments stuffs

export const syncInvestmentAccounts = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/accounts/sync${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'PATCH',
})

export const flushInvestmentCache = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/flush-cache${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'PATCH',
})

export const getInvestmentAccounts = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/accounts${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentAssetAdjustments = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/invoices/asset-adjustments${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmenBondHoldings = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/household-bond-holdings${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentPositions = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/positions${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentProjectedIncome = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/projected-income${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentAUM = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/firm-aum${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentAccountBalancesWithCash = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/firm-account-balances-with-cash${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getFirmDonatedSecurities = (
  orgId: string,
  query?: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/firm-donated-securities${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentFirmHoldings = (
  orgId: string,
  data: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/firm-holdings`,
  method: 'POST',
  data,
})

export const getInvestmentFirmRevenues = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/firm-revenues${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentFirmSummary = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/firm-summary${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentFirmAccountAllocationsByClient = (
  orgId: string,
  data: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/firm-allocations-by-client`,
  method: 'POST',
  data,
})

export const getInvestmentFirmAccountValuesByClient = (
  orgId: string,
  query?: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/firm-account-values-by-client${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentHouseholds = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/households${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentLastPositionDate = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/last-position-date${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentHouseholdPerformance = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/household-performance${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentHistoricPerformance = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/historic-household-performance${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentSummaryByRange = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/summary/range${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getAnnualInvestmentSummary = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/summary/annual${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getHistoricInvestmentSummary = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/summary/historic${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInceptionInvestmentSummary = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/summary/inception${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentTransactions = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/transactions${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentSPYBreakdown = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/etf-breakdown/spy${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentExpenses = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/expenses${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentIncomes = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/incomes${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentBalances = (orgId: string): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/balances`,
  method: 'GET',
})

export const getInvestmentBalancesByAccount = (
  orgId: string
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/balances/accounts`,
  method: 'GET',
})

export const getInvestmentBuySellByAccount = (
  orgId: string
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/buy-sell`,
  method: 'GET',
})

export const getInvestmentHistoricBalances = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/historic-balances${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentBenchmarks = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/benchmarks${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentHouseholdHoldings = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/household-holdings${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getInvestmentTargetAllocations = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/target-allocations${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getBillings = (orgId: string, query: object): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/invoices${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const getEvestechBillings = (
  orgId: string,
  data: object
): APIResponseType => ({
  data,
  method: 'POST',
  url: `/orgs/v1/${orgId}/investments/invoices`,
})

export const getSavedQuarterlyInvestmentReports = (
  orgId: string,
  query: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/quarterly-reports${
    query ? `?${queryString.stringify(query)}` : ''
  }`,
  method: 'GET',
})

export const saveQuarterlyInvestmentReports = (
  orgId: string,
  data: object
): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/quarterly-reports`,
  method: 'POST',
  data,
})

export const getLogoUrl = (): APIResponseType => ({
  url: `/orgs/v1/logo-url`,
  method: 'GET',
})

export const fetchHoldingTypeOptions = (orgId: string): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/firm-holding-types`,
  method: 'GET',
})

export const fetchHoldingSubTypeOptions = (orgId: string): APIResponseType => ({
  url: `/orgs/v1/${orgId}/investments/firm-holding-subtypes`,
  method: 'GET',
})
