import { makeAutoObservable } from 'mobx'
import { GridFilterModel } from '@mui/x-data-grid-premium'

export const FILTER_STORE_KEY = 'filterStoreModel'

class FilterStore {
  private filterModels: Map<string, GridFilterModel> = new Map()

  constructor() {
    makeAutoObservable(this)
  }

  // Get the filter model for a specific DataGrid by its unique id
  getFilterModel(dataGridId: string): GridFilterModel | null {
    return this.filterModels.get(dataGridId) || null
  }

  // Set a new filter model for a specific DataGrid
  setFilterModel(dataGridId: string, newModel: GridFilterModel) {
    this.filterModels.set(dataGridId, newModel)
  }

  // Clear the filter model for a specific DataGrid
  clearFilterModel(dataGridId: string) {
    this.filterModels.delete(dataGridId)
  }

  serializeModels(): string {
    return JSON.stringify(Array.from(this.filterModels.entries()))
  }

  restoreModels(serializedData: string): void {
    const parsed = new Map(JSON.parse(serializedData))
    parsed.forEach((value, key) => this.filterModels.set(key, value))
  }
}

// eslint-disable-next-line import/prefer-default-export
export const filterStore = new FilterStore()

// Optionally persist to localStorage or sessionStorage
window.addEventListener('beforeunload', () => {
  const serializedModel = filterStore.serializeModels()
  localStorage.setItem(FILTER_STORE_KEY, serializedModel)
})

// Restore from localStorage
const storedModel = localStorage.getItem(FILTER_STORE_KEY)
if (storedModel) {
  filterStore.restoreModels(storedModel)
}
// RubyMine no like, AI likes
// export default filterStore
